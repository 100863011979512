import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import React from 'react';
import BackButton from 'src/components/backButton/BackButton';
import Layout from 'src/components/layout/Layout';
import PageContentNarrow from 'src/components/pageContentNarrow/PageContentNarrow';
import NotFoundPage from 'src/pages/404';
import { GoBackLink, Button } from './careersJobPage.styled';
import ApplyForm from './components/applyForm/ApplyForm';
import JobPreview from './components/jobPreview/JobPreview';
import JobSummary from './components/jobSummary/JobSummary';
import { useJob } from './hooks/useJob/useJob';

interface IProps {
  shortcode: string;
}

const CareersJobPage = (props: IProps) => {
  const { state } = useLocation() as { state: { canGoBack?: boolean } | null };

  const { job, loading } = useJob(props.shortcode as string);

  if (!loading && !job) {
    return <NotFoundPage />;
  }

  return (
    <Layout>
      <>
        {!!job && (
          <>
            <PageContentNarrow>
              <GoBackLink onClick={() => (state?.canGoBack ? navigate(-1) : navigate('/careers#jobs'))}>
                <BackButton />
              </GoBackLink>
              <JobSummary
                title={job.title}
                location={job.locations.map(j => j.country_name).join(', ')}
                employmentType={job.employment_type}
                experience={job.experience}
              />
              <Button to="#apply-form">Apply Now</Button>
              <JobPreview description={job.description} />
              <ApplyForm shortcode={props.shortcode} />
            </PageContentNarrow>
          </>
        )}
      </>
    </Layout>
  );
};

export default CareersJobPage;
